<template>
  <b-card-code>
    <div class="table-responsive mb-4" v-if="admission_instruction.id">
      <table class="table table-hover table-bordered">
        <thead>
          <tr>
            <td style="text-align:center">Admission Notice</td>
            <td style="text-align:center">Admission Instruction</td>
            <td style="text-align:center">Payment Instruction</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="text-align:center">
              <a :href="imgShow(admission_instruction.admission_notice)" target="_blank">
                <feather-icon icon="EyeIcon" class="mr-50"/>
              </a>
            </td>
            <td style="text-align:center">
              <a :href="imgShow(admission_instruction.admission_instruction)" target="_blank">
                <feather-icon icon="EyeIcon" class="mr-50"/>
              </a>
            </td>
            <td style="text-align:center">
              <a :href="imgShow(admission_instruction.payment_instruction)" target="_blank">
                <feather-icon icon="EyeIcon" class="mr-50"/>
              </a>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form ref="institute_form">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Admission Notice')}}</span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="admission notice"
                  :rules="admission_instruction.id?'':'required'"
              >
                <b-form-file
                    v-model="admission_notice"
                    name="admission_notice"
                    :state="errors.length > 0 ? false:null"
                    placeholder="select a pdf"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Admission Instruction')}}</span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="admission instruction"
                  :rules="admission_instruction.id?'':'required'"
              >
                <b-form-file
                    v-model="ad_instruction"
                    name="admission_instruction"
                    :state="errors.length > 0 ? false:null"
                    placeholder="select a pdf"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Payment Instrumentation')}}</span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="payment instruction"
                  :rules="admission_instruction.id?'':'required'"
              >
                <b-form-file
                    v-model="payment_instruction"
                    name="payment_instruction"
                    :state="errors.length > 0 ? false:null"
                    placeholder="select a pdf"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- submit button -->
          <b-col md="4" v-if="$can('instruction','Admission')">
            <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
            >
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BFormGroup,BFormFile,
  BForm,BRow, BCol,BCardText
} from 'bootstrap-vue'
import {required} from '@validations'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
export default {
  name:'Instruction',
  components: {
    BCardCode,
    BFormGroup,
    BCardText,
    BButton,BFormFile,
    BForm,BRow, BCol,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      admission_notice:null,
      ad_instruction:null,
      payment_instruction:null,
      required,
      pageLength: 5,
      dir: false,
    }
  },
  methods:{
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.institute_form);
          apiCall.post('/admission/instruction/information/storeOrUpdate',data).then((response)=>{
            this.$store.dispatch('GET_ALL_ADMISSION_INSTRUCTION');
            this.admission_notice=null;
            this.ad_instruction=null;
            this.payment_instruction=null;
            this.$refs.simpleRules.reset();
            this.$toaster.success(response.data.message);
          }).catch((error)=>{
            if (error.response.status ==422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            }
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    imgShow(path) {
      return process.env.VUE_APP_ENV_RESOURCHURL + path;
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['admission_instruction']),
  },
  created() {
    this.$store.dispatch('GET_ALL_ADMISSION_INSTRUCTION');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>